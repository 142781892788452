.App {
  text-align: center;
}

#root,
.app-container {
  height: inherit;
}

#root .progress-loader.MuiLinearProgress-root {
  position: absolute;
  width: 100%;
  height: 2px;
}

.auth-container {
  color: white;
  height: inherit;
  background: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
}

.auth-container .actions-block {
  margin-top: 15px;
  width: 85%;
}

.auth-container .actions-block button {
  text-transform: none;
  margin-top: 18px;
}

.auth-container .sign-in-block {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-container .auth-title {
  margin-top: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapAnywhere {
  overflow-wrap: anywhere;
}

.block {
  display: block !important;
}

.MuiList-root > .MuiTablePagination-menuItem {
  display: block;
  padding-left: 14px;
}
